<template>
  <div class="page-info-content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="page-form"
      label-position="left"
      label-width="140px"
    >
      <div class="form-item-row">
        <el-form-item
          label="代采机构："
          prop="employerId"
          :rules="rules.selectiveType"
        >
          <el-select
            v-model="ruleForm.employerId"
            placeholder="请选择"
            @change="getAgentCreditInfoDetails"
          >
            <el-option
              v-for="(item, index) in agentCreditInfoList"
              :key="index"
              :label="item.fundsCmpName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="合作企业："
          prop="supplierCmpId"
          :rules="rules.selectiveType"
        >
          <el-select
            v-model="ruleForm.supplierCmpId"
            filterable
            :remote-method="getAgentSupplierList"
            placeholder="请选择合作企业"
            @change="getSupplierCmpId"
          >
            <el-option
              v-for="(item, index) in agentSupplierList"
              :key="index"
              :label="item.partnerName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.isShow" label="采购期限：">
          {{ ruleForm.maxPurchaseDays2 }}
        </el-form-item>
        <el-form-item v-if="ruleForm.isShow" label="货款延期支付天数：">
          {{ ruleForm.deferredDays }}天
        </el-form-item>
        <el-form-item v-if="ruleForm.isShow" label="逾期费率：">
          {{ ruleForm.overdueInterestRate }}
        </el-form-item>
        <el-form-item v-if="ruleForm.isShow" label="结算日期：">
          <div class="flexBox">
            <span>每月</span>{{ ruleForm.settlementDate }}号
          </div>
        </el-form-item>
        <el-form-item v-if="ruleForm.isShow" label="交收仓库：">
          {{ ruleForm.deliveryWarehouseName }}
        </el-form-item>
        <el-form-item v-if="ruleForm.isShow" label="交货地址：">
          {{ ruleForm.deliverWarehouseAddr }}
        </el-form-item>
        <!-- <el-form-item v-if="ruleForm.interestAccrual1" label="按比率计息：">
          {{ ruleForm.interestAccrual1 }}
        </el-form-item>
        <el-form-item v-if="ruleForm.interestAccrual2" label="周期加价：">
          {{ ruleForm.interestAccrual2 }}
        </el-form-item> -->
        <el-form-item
          v-if="ruleForm.agentRateConfigId1 || ruleForm.agentRateConfigId2"
          :rules="rules.selectiveType"
          label="计息方式："
          prop="agentRateConfigId"
        >
          <el-radio
            v-if="ruleForm.agentRateConfigId1"
            v-model="ruleForm.agentRateConfigId"
            name="agentRateConfigId"
            :label="ruleForm.agentRateConfigId1"
            @change="computationalCosts"
          >
            按比率计息({{ ruleForm.interestAccrual1 }})
          </el-radio>
          <el-radio
            v-if="ruleForm.agentRateConfigId2"
            v-model="ruleForm.agentRateConfigId"
            name="agentRateConfigId"
            :label="ruleForm.agentRateConfigId2"
            @change="computationalCosts"
          >
            周期加价({{ ruleForm.interestAccrual2 }})
          </el-radio>
          <el-radio
            v-if="ruleForm.agentRateConfigId3"
            v-model="ruleForm.agentRateConfigId"
            name="agentRateConfigId"
            :label="ruleForm.agentRateConfigId3"
            @change="computationalCosts"
          >
            固定加价({{ ruleForm.interestAccrual3 }})
          </el-radio>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.agentRateConfigId1 || ruleForm.agentRateConfigId2"
          label="交易类型："
          :rules="rules.selectiveType"
          prop="transType"
        >
          <el-radio
            v-model="ruleForm.transType"
            name="transType"
            :label="1"
            :disabled="ruleForm.transType1 !== 3"
          >
            锁量
          </el-radio>
          <el-radio
            v-model="ruleForm.transType"
            name="transType"
            :label="2"
            :disabled="ruleForm.transType1 !== 3"
          >
            锁价
          </el-radio>
        </el-form-item>
        <el-form-item
          label="计划代采天数："
          prop="purchaseDays"
          :rules="rules.message"
        >
          <el-input-number
            v-model="ruleForm.purchaseDays"
            :min="1"
            disabled
            :max="ruleForm.maxPurchaseDays"
            @change="getProcurement"
          />
        </el-form-item>
        <el-form-item
          label="开票方式："
          prop="invoicingType"
          :rules="rules.selectiveType"
        >
          <el-select v-model="ruleForm.invoicingType" placeholder="请选择">
            <el-option label="一票制" value="01" />
            <el-option label="多票制" value="02" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="备注：" prop="remark">
          <el-input v-model="ruleForm.remark" placeholder="请输入备注" maxlength="30" show-word-limit type="textarea" />
        </el-form-item> -->
      </div>
      <el-form-item v-if="ruleForm.rateType === 2" label="阶梯费用" prop="rate">
        <Table
          :item-data="rateLadderItemData"
          :list-data="ruleForm.rateLadderConfigList || []"
        />
      </el-form-item>
      <div>
        <!-- 表格 -->
        <div class="purchase-goods">
          <h3 class="page-subtitle-shade">
            采购货物明细
          </h3>
          <div class="list-operation">
            <!-- <span class="tip">提示：代采天数不满七天按七天计息</span> -->
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="primary"
              @click="addPurchaseGoods"
            >
              商品库添加
            </el-button>
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="primary"
              @click="autonomousAddition"
            >
              自主添加
            </el-button>
          </div>
          <Table
            :item-data="itemData"
            :list-data="ruleFormGoodsList"
            :operation-button="operationButton"
            max-height="520"
            :is-border="true"
            :overflow-tooltip="false"
          >
            <template #goodsName="{ row, index }">
              <el-form-item class="table-form-item" label-width="0">
                <div @click="clickItemIndex = index">
                  <span v-if="row.id">{{ row.goodsName }}</span>
                  <ProductName
                    v-else
                    :default-value="row.defaultValue"
                    @getProduct="getProduct"
                  />
                </div>
              </el-form-item>
            </template>
            <template #goodsMaterials="{ row, index }">
              <el-form-item class="table-form-item" label-width="0">
                <span v-if="row.id">{{ row.goodsMaterials }}</span>
                <el-select
                  v-else
                  v-model="row.goodsMaterials"
                  v-maxlength="100"
                  allow-create
                  default-first-option
                  filterable
                  placeholder="请选择材质"
                  @change="getCurrentId(row, index)"
                >
                  <el-option
                    v-for="(item, ind) in row.materialsArr"
                    :key="ind"
                    :label="item.materials"
                    :value="item.materials"
                  />
                </el-select>
              </el-form-item>
            </template>
            <template #goodsSpecs="{ row, index }">
              <el-form-item class="table-form-item" label-width="0">
                <span v-if="row.id">{{ row.goodsSpecs }}</span>
                <el-select
                  v-else
                  v-model="row.goodsSpecs"
                  v-maxlength="100"
                  allow-create
                  default-first-option
                  filterable
                  placeholder="请选择规格"
                  @change="getCurrentId(row, index)"
                >
                  <el-option label="混合规格" value="-1" />
                  <el-option
                    v-for="(item, ind) in row.specificationArr"
                    :key="ind"
                    :label="item.specs"
                    :value="item.specs"
                  />
                </el-select>
              </el-form-item>
            </template>
            <template #goodsProduct="{ row, index }">
              <el-form-item class="table-form-item" label-width="0">
                <span v-if="row.id">{{ row.goodsProduct }}</span>
                <el-select
                  v-else
                  v-model="row.goodsProduct"
                  v-maxlength="100"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择产地"
                  reserve-keyword
                  remote
                  :remote-method="remoteMethod"
                  @change="getCurrentId(row, index)"
                >
                  <i
                    slot="prefix"
                    class="el-icon-arrow-down el-input__icon"
                  />
                  <el-option label="不限" value="不限" />
                  <el-option
                    v-for="item in restaurants"
                    :key="item.id"
                    :label="item.brandProduction"
                    :value="item.brandProduction"
                  />
                </el-select>
              </el-form-item>
            </template>
            <template #goodsWeights="{ row, index }">
              <el-form-item class="table-form-item" label-width="0">
                <span v-if="row.goodsWeights === '-1' && row.id">{{
                  row.goodsWeightsStr
                }}</span>
                <span v-else-if="row.id">{{ row.goodsWeights }}</span>
                <el-select
                  v-else
                  v-model="row.goodsWeights"
                  v-maxlength="6"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择件重"
                  @change="getCurrentId(row, index, 'goodsWeights')"
                >
                  <el-option label="不限" value="-1" />
                  <el-option
                    v-for="(item, ind) in row.weightArr"
                    :key="ind"
                    :label="item.weights"
                    :value="item.weights"
                  />
                </el-select>
              </el-form-item>
            </template>
            <template #purchasePrice="{ row }">
              <el-form-item class="table-form-item" label-width="0">
                <el-input-number
                  v-model="row.purchasePrice"
                  :min="0.01"
                  :precision="2"
                  :step="1"
                  @change="getUnitWeight(row)"
                />
              </el-form-item>
            </template>
            <template #purchaseWeight="{ row }">
              <el-form-item class="table-form-item" label-width="0">
                <el-input-number
                  v-model="row.purchaseWeight"
                  :min="0.001"
                  :precision="3"
                  :step="1"
                  @change="getUnitWeight(row)"
                />
              </el-form-item>
            </template>
            <template #purchaseUnit="{ row }">
              <el-form-item class="table-form-item" label-width="0">
                <el-select v-model="row.purchaseUnit" placeholder="请选择">
                  <!-- 取的是字典项 -->
                  <el-option
                    v-for="item in $store.getters.getDictionaryItem(
                      'purchaseUnit'
                    )"
                    :key="item.dictId"
                    :label="item.dictName"
                    :value="item.dictId"
                  />
                </el-select>
              </el-form-item>
            </template>
          </Table>
        </div>
      </div>
      <div class="form-item-row">
        <h3 class="page-subtitle-shade">
          预计代采费用
        </h3>
        <el-form-item label="货物总额(元)：" prop="totalPayment">
          <el-input v-model="ruleForm.totalPayment" disabled />
        </el-form-item>
        <el-form-item label="代采费用(元)：" prop="estimateCost">
          <el-input v-model="ruleForm.estimateCost" disabled />
        </el-form-item>
        <el-form-item label="保证金比例(%)：" prop="depositRate">
          <el-input v-model="ruleForm.depositRate" disabled />
        </el-form-item>
        <el-form-item label="保证金金额(元)：" prop="deposit">
          <el-input v-model="ruleForm.deposit" disabled />
        </el-form-item>
        <el-form-item label="采购总金额(元)：" prop="deposit">
          <el-input v-model="ruleForm.totalPlannedAmount" disabled />
        </el-form-item>
        <el-form-item
          v-if="
            ruleForm.transType === 1 && ruleForm.paymentConfigList.length > 0
          "
          class="detailsMargin"
          label="保证金明细："
          prop="rate"
        >
          <Table
            :item-data="paymentConfigItemData"
            :is-border="true"
            :list-data="ruleForm.paymentConfigList || []"
          >
            <template #paymentDate="scoped">
              每月{{ scoped.row.paymentDate }}日
            </template>
          </Table>
        </el-form-item>
      </div>
      <div class="form-operation button-combination">
        <el-button size="small" plain class="widen-button" @click="cancel">
          取消
        </el-button>
        <el-button
          size="small"
          :disabled="disabled"
          type="primary"
          :loading="$store.state.loading"
          class="widen-button"
          @click="submitForm"
        >
          提交
        </el-button>
      </div>
    </el-form>
    <!-- 选择上游未填时有的商品商品 -->
    <ChooseMatterWeight
      :id="pnameId"
      ref="chooseMatterWeight"
      :selected-arr="selectedArr"
      :show-radio="false"
      @getCheckId="getCheckId"
    />
    <!-- 选择带过来的商品 zsd -->
    <el-dialog
      title="选择商品"
      :visible.sync="goodsVisible"
      class="dialog"
      :close-on-click-modal="false"
      @click="goodsVisible = false"
    >
      <Table
        ref="goodsListTable"
        :selection="true"
        :item-data="goodsItemData"
        :list-data="allGoodsList"
        :max-height="'540px'"
        @getCheckData="getCheckData"
      />
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          plain
          class="widen-button"
          @click="goodsVisible = false"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          class="title widen-button"
          @click="submitCheckGoods"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import ProductName from '@/components/ProductName'
import ChooseMatterWeight from '@/components/ChooseMatterWeight'
import {
  goodSkuId,
  agentPurchasePlanCheckGoodsLegal,
  agentSupplierListSupplierCmpByFundsCmpCode,
  agentPurchasePlanAdd,
  agentPurchasePlanAgentPlanInfoForAdd
} from '@/http/custApi/purchasePlanManage'
import { agentFinancialInfoList } from '@/http/custApi/quotaManage'
import {
  getGoodsMaterialsList,
  getGoodsSpecsList,
  getGoodsProductionPage,
  getGoodsWeightsByGoodsId
} from '@/http/custApi/common'
import { rules } from '@/unit/matchFormRegex'
import { roundUpToInteger, subPrecision } from '@/unit/index'
export default {
  components: {
    Table,
    ProductName,
    ChooseMatterWeight
  },
  directives: {
    maxlength(el, binding, vnode) {
      const inputcontrol = el.querySelector('.el-input__inner')
      inputcontrol.setAttribute('maxlength', binding.value)
    }
  },
  data() {
    return {
      pnameId: '',
      changePnameIndex: '',
      disabled: false,
      goodsVisible: false,
      restaurants: [],
      ruleFormGoodsList: [],
      clickItemIndex: 0,
      autonomousAdditionArr: [], // 自主添加且未转化的
      selectedArr: [], // 已选择的商品库数据
      ruleForm: {
        creditId: '',
        transType: '',
        supplierCmpId: '',
        employerId: '',
        deliveryType: 2,
        invoicingType: '', // 开票方式
        agentRateConfigList: [{ rateValue: '' }],
        goodsList: [], // 货物明细
        paymentConfigList: [], // 付款比例配置
        custCmpCode: this.$getUserInfoParameter('cmpId'),
        totalPayment: 0 // 货款总额
      },
      rules,
      paymentConfigItemData: [
        { label: '付款日期', prop: 'paymentDate' },
        { label: '付款比例(%)', prop: 'paymentRate', unit: '%' },
        { label: '保证金金额(元)', prop: 'marginAmount' }
      ],
      rateLadderItemData: [
        { label: '价格下限', prop: 'lowerLimit' },
        { label: '价格上限', prop: 'upperLimit' },
        { label: '基数(元/吨)', prop: 'base' },
        { label: '规则', prop: 'operFormula' }
      ],
      goodsItemData: [
        { label: '品名', prop: 'goodsName', width: '120px' },
        { label: '材质', prop: 'goodsMaterials', width: '80px' },
        { label: '规格', prop: 'goodsSpecs', width: '80px' },
        { label: '产地', prop: 'goodsProduct', width: '120px' },
        { label: '件重(吨)', prop: 'goodsWeights', width: '80px' }
      ],
      itemData: [
        { label: '品名', slotName: 'goodsName', width: '200px' },
        { label: '材质', prop: 'goodsMaterials', width: '200px' },
        { label: '规格', prop: 'goodsSpecs', width: '200px' },
        {
          label: '采购数量(吨)',
          slotName: 'purchaseWeight',
          width: '200px',
          required: true
        },
        {
          label: '采购单价(元/吨)',
          slotName: 'purchasePrice',
          width: '200px',
          required: true
        },
        {
          label: '采购单位',
          slotName: 'purchaseUnit',
          width: '120px',
          required: true
        },
        { label: '产地', prop: 'goodsProduct', width: '200px' },
        { label: '件重(吨)', prop: 'goodsWeights', width: '200px' },
        // { label: '采购数量', slotName: 'purchaseQuantity', width: '200px' },
        { label: '金额小计(元)', prop: 'totalPrice', width: '140px' }
      ],
      operationButton: [
        {
          bType: 'danger',
          icon: 'el-icon-remove-outline',
          handleEvent: this.deleteRow
        }
      ],
      times: null,
      agentSupplierList: [], // 合作企业列表
      agentCreditInfoList: [], // 资方列表
      creditInfoId: '',
      allGoodsList: [], // 关联货物列表
      checkGoodsList: [],
      materialsArr: [], // 材质
      weightArr: [], // 件重
      specificationArr: [] // 规格
    }
  },
  watch: {
    ruleForm: {
      handler(val) {
        this.computationalCosts()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    agentFinancialInfoList(
      { pageNum: 1, pageSize: 100, creditStatus: 0 },
      (res) => {
        this.agentCreditInfoList = [...res.data.pageData]
      }
    )
    this.remoteMethod('')
  },
  beforeDestroy() {
    clearTimeout(this.times)
  },
  methods: {
    // 当数据改变时获取当前的库里面的id
    getCurrentId(row, index, type) {
      if (type === 'goodsWeights' && !Number(row[type])) {
        this.$set(row, 'goodsWeights', '')
        this.$message.error('请输入数字')
        return
      }
      if (
        !row.goodsMaterials ||
        !row.goodsSpecs ||
        !row.goodsProduct ||
        !row.goodsName ||
        !row.goodsWeights
      ) {
        return
      }
      // 先效验商品是否合法
      const efficacyDataArr = this.ruleFormGoodsList.filter(
        (item) =>
          item.goodsMaterials &&
          item.goodsSpecs &&
          item.goodsProduct &&
          item.goodsName
      )
      efficacyDataArr.forEach((item) => {
        item.goodsWeights = item.goodsWeights === '-' ? '' : item.goodsWeights
      })
      agentPurchasePlanCheckGoodsLegal(
        efficacyDataArr,
        () => {
          goodSkuId(
            {
              goodsId: row.goodsId,
              specs: row.goodsSpecs,
              materials: row.goodsMaterials,
              productions: row.goodsProduct
            },
            (res) => {
              if (res.data && res.data.id) {
                this.$set(this.ruleFormGoodsList[index], 'id', res.data.id)
              }
              if (row.goodsWeights === '-1') {
                this.$set(
                  this.ruleFormGoodsList[index],
                  'goodsWeightsStr',
                  '不限'
                )
              }
            }
          )
        },
        (err) => {
          this.$message.closeAll()
          this.$alert(
            err.msg,
            err.code === 3334 ? '商品重复提醒' : '商品冲突提醒',
            {
              confirmButtonText: '我知道了',
              showClose: false,
              center: true,
              callback: () => {
                // 完成通信后关闭WebSocket连接
              }
            }
          )
        }
      )
    },
    // 产地的联动狂
    remoteMethod(queryString) {
      const value = queryString instanceof Object ? '' : queryString
      getGoodsProductionPage(
        { pageNum: 1, pageSize: 5000, brandProduction: value || '' },
        (res) => {
          this.restaurants = res.data.pageData
        }
      )
    },
    // 点击自主添加品名的时候获取他的品名跟id
    getProduct(obj) {
      this.$set(
        this.ruleFormGoodsList[this.clickItemIndex],
        'goodsId',
        obj.value[2]
      )
      this.$set(
        this.ruleFormGoodsList[this.clickItemIndex],
        'goodsName',
        obj.label[2]
      )
      this.$set(
        this.ruleFormGoodsList[this.clickItemIndex],
        'defaultValue',
        obj.value || []
      )
      this.$set(this.ruleFormGoodsList[this.clickItemIndex], 'goodsSpecs', '')
      this.$set(
        this.ruleFormGoodsList[this.clickItemIndex],
        'goodsMaterials',
        ''
      )
      this.$set(
        this.ruleFormGoodsList[this.clickItemIndex],
        'goodsProduct',
        ''
      )
      this.$set(
        this.ruleFormGoodsList[this.clickItemIndex],
        'goodsWeights',
        ''
      )
      this.getOther(obj.value)
    },
    // 联动品名获取其他的属性数组
    getOther(arr) {
      if (arr.length === 0) return
      // 材质
      getGoodsMaterialsList(
        { state: 1, categoryId: arr[0], varietyId: arr[1], goodsId: arr[2] },
        (res) => {
          // this.materialsArr = res.data.filter(item => item.materials !== '-')
          // this.materialsArr = [...res.data]
          this.$set(
            this.ruleFormGoodsList[this.clickItemIndex],
            'materialsArr',
            [...res.data]
          )
        }
      )
      // 规格
      getGoodsSpecsList(
        {
          state: 1,
          categoryId: arr[0],
          varietyId: arr[1],
          goodsId: arr[2],
          pageSize: 1000
        },
        (res) => {
          // this.specificationArr = [...res.data]
          this.$set(
            this.ruleFormGoodsList[this.clickItemIndex],
            'specificationArr',
            [...res.data]
          )
        }
      )
      // 件重
      getGoodsWeightsByGoodsId(
        {
          state: 1,
          categoryId: arr[0],
          varietyId: arr[1],
          goodsId: arr[2],
          pageNum: 1,
          pageSize: 5000
        },
        (res) => {
          this.weightArr =
            res.data.pageData && res.data.pageData[0]
              ? [...res.data.pageData]
              : []
          this.$set(
            this.ruleFormGoodsList[this.clickItemIndex],
            'weightArr',
            this.weightArr
          )
        }
      )
    },
    // 自主添加按钮点击
    autonomousAddition() {
      if (!this.ruleForm.employerId) {
        this.$message.error('请先填写代采机构')
        return
      }
      if (!this.ruleForm.supplierCmpId) {
        this.$message.error('请先填写合作企业')
        return
      }
      if (!this.ruleForm.agentRateConfigId) {
        this.$message.error('请先填写费率类型')
        return
      }
      this.ruleFormGoodsList.unshift({
        purchaseUnit: '5',
        purchaseWeight: '',
        defaultValue: [],
        purchasePrice: ''
      })
      this.$forceUpdate()
    },
    // 计算费用
    computationalCosts() {
      const {
        totalPayment,
        depositRate,
        paymentConfigList = [],
        purchaseDays
      } = this.ruleForm
      const initDepositRate = Number(depositRate) / 100
      if (parseInt(totalPayment) >= 0 && depositRate) {
        // 保证金金额
        this.ruleForm.deposit = roundUpToInteger(
          Number(totalPayment) * initDepositRate
        )
        // 保证金明细金额
        paymentConfigList.forEach((item) => {
          if (item.paymentRate) {
            const amount = roundUpToInteger(
              Number(totalPayment) *
                initDepositRate *
                (Number(item.paymentRate) / 100)
            )
            this.$set(item, 'marginAmount', amount)
          }
        })
      }
      let type = null
      let rateObj = {}
      this.ruleForm.agentRateConfigList.forEach((item) => {
        if (item.id === this.ruleForm.agentRateConfigId) {
          type = item.rateUnit
          rateObj = { ...item }
        }
      })
      this.$forceUpdate()
      // 固定加价的时候
      if (type === 3) {
        this.ruleForm.estimateCost = roundUpToInteger(
          Number(rateObj.rateValue) * Number(this.ruleForm.totalWeight)
        )
        this.$set(
          this.ruleForm,
          'totalPlannedAmount',
          roundUpToInteger(
            Number(this.ruleForm.totalPayment) +
              Number(this.ruleForm.estimateCost)
          )
        )
        return
      }
      if (type === 2) {
        // 计算年月日分别不同的计算  不满的向上取整
        let num = 1
        switch (rateObj.rateCycle) {
          case 1:
            num = purchaseDays
            break
          case 2:
            num = Math.ceil(purchaseDays / 7)
            break
          case 3:
            num = Math.ceil(purchaseDays / 30)
            break
          case 4:
            num = Math.ceil(purchaseDays / 360)
            break
        }
        this.ruleForm.estimateCost = roundUpToInteger(
          Number(rateObj.rateValue) * Number(this.ruleForm.totalWeight) * num
        )
        this.$set(
          this.ruleForm,
          'totalPlannedAmount',
          roundUpToInteger(
            Number(this.ruleForm.totalPayment) +
              Number(this.ruleForm.estimateCost)
          )
        )
        return
      }
      // 预计采购费用
      if (type === 1 && purchaseDays) {
        // 计算年月日分别不同的计算
        let num = 1
        switch (rateObj.rateCycle) {
          case 1:
            num = 1
            break
          case 2:
            num = 7
            break
          case 3:
            num = 30
            break
          case 4:
            num = 360
            break
        }
        this.ruleForm.estimateCost = roundUpToInteger(
          (subPrecision(Number(totalPayment), Number(this.ruleForm.deposit)) *
            (Number(rateObj.rateValue) / 100) *
            (purchaseDays < 7 ? 7 : purchaseDays)) /
            num
        )
        this.$set(
          this.ruleForm,
          'totalPlannedAmount',
          roundUpToInteger(
            Number(this.ruleForm.totalPayment) +
              Number(this.ruleForm.estimateCost)
          )
        )
      }
    },
    // 获取选择好的数据
    getCheckData(arr) {
      this.checkGoodsList = [...arr]
    },
    submitCheckGoods() {
      if (this.checkGoodsList.length === 0) {
        this.$message.error('请最少选择一个商品明细')
        return
      }
      this.ruleFormGoodsList = [...this.checkGoodsList]
      this.goodsVisible = false
    },
    // 获取选择品名过来的数据
    // getCheckId(row) {
    //   const arr = []
    //   if (row && row.length >= 0) {
    //     row.forEach(item => {
    //       arr.push({
    //         goodsMaterials: item.materials,
    //         goodsName: item.goodsName,
    //         goodsSpecs: item.specs,
    //         goodsWeights: item.weights,
    //         goodsProduct: item.productions,
    //         id: item.id,
    //         goodsId: item.goodsId,
    //         flagId: item.id,
    //         purchaseUnit: item.purchaseUnit || '5',
    //         purchasePrice: item.purchasePrice || '',
    //         purchaseWeight: item.purchaseWeight || ''
    //       })
    //     })
    //   }
    //   this.ruleFormGoodsList = [...arr]
    // },
    getCheckId(value) {
      // 把自主添加且为转换的加回去
      const newArr = this.autonomousAdditionArr.concat(value || [])
      this.ruleFormGoodsList = [...newArr]
      for (let index = 0; index < this.ruleFormGoodsList.length; index++) {
        const element = this.ruleFormGoodsList[index]
        this.$set(
          element,
          'goodsMaterials',
          element.materials || element.goodsMaterials
        )
        this.$set(
          element,
          'goodsProduct',
          element.productions || element.goodsProduct
        )
        this.$set(element, 'goodsSpecs', element.specs || element.goodsSpecs)
        this.$set(
          element,
          'goodsWeights',
          element.weights || element.goodsWeights || '-'
        )
        this.$set(element, 'goodsName', element.goodsName || element.goodsName)
        this.$set(element, 'defaultValue', element.defaultValue || [])
        this.$set(element, 'purchaseUnit', element.purchaseUnit || '5')
        this.$set(element, 'purchasePrice', element.purchasePrice || '')
        this.$set(element, 'purchaseWeight', element.purchaseWeight || '')
        this.$set(element, 'id', element.id || '')
        this.$set(element, 'goodsId', element.goodsId || '')
        this.$set(element, 'flagId', element.flagId || element.id)
      }
    },
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
    },
    getProcurement() {
      if (!this.ruleForm.employerId || !this.ruleForm.supplierCmpId) {
        this.$message.error('请先填写代采机构跟合作企业')
        this.$set(this.ruleForm, 'purchaseDays', 0)
      }
    },
    getPname(row, index) {
      this.changePnameIndex = index
      this.pnameId = row.code
      this.$refs.chooseMatterWeight.dialogTableVisible = true
    },
    // 获取计划详情
    getPlanInfoForAdd() {
      agentPurchasePlanAgentPlanInfoForAdd(
        {
          custCmpCode: this.$getUserInfoParameter('cmpId'),
          supplierCmpId: Number(this.ruleForm.supplierCmpId),
          fundsCmpCode: this.ruleForm.employerCmpCode
        },
        (res) => {
          if (
            res.data.agentRateConfigList &&
            res.data.agentRateConfigList.length > 0
          ) {
            // 默认选中第一个费率类型
            res.data.agentRateConfigId =
              res.data.agentRateConfigList[0].id || ''
            res.data.agentRateConfigList.forEach((item) => {
              switch (item.rateUnit) {
                case 1:
                  if (item.rateValue) {
                    res.data.agentRateConfigId1 = item.id
                    res.data.interestAccrual1 = `${item.rateValue}%/${
                      item.rateCycle === 1 ? '天' : '年'
                    }`
                  }
                  break
                case 2:
                  if (item.rateValue) {
                    res.data.agentRateConfigId2 = item.id
                    res.data.interestAccrual2 = `${item.rateValue}元/吨/${
                      item.rateCycle === 1 ? '天' : '周'
                    }`
                  }
                  break
                case 3:
                  if (item.rateValue) {
                    res.data.agentRateConfigId3 = item.id
                    res.data.interestAccrual3 = `${item.rateValue}元/吨`
                  }
                  break
              }
            })
          }
          this.ruleForm = {
            ...res.data,
            agentRateConfigList:
              res.data.rateType === 1
                ? [...(res.data.agentRateConfigList || { rateValue: '' })]
                : [],
            employerId: this.ruleForm.employerId,
            transType1: this.ruleForm.transType1,
            transType: this.ruleForm.transType,
            invoicingType: this.ruleForm.invoicingType,
            purchaseDays: Number(res.data.maxPurchaseDays) || 0
          }
          if (this.ruleForm.rateType === 1 && res.data.agentRateConfigList[0]) {
            switch (res.data.agentRateConfigList[0].rateUnit) {
              case 1:
                this.ruleForm.rateValue2 =
                  res.data.agentRateConfigList[0].rateValue + '%'
                break
              case 2:
                this.ruleForm.rateValue2 =
                  res.data.agentRateConfigList[0].rateValue + '元/吨'
                break
              case 3:
                this.ruleForm.rateValue2 =
                  res.data.agentRateConfigList[0].rateValue + '元/吨'
                break
              default:
                break
            }
            switch (res.data.agentRateConfigList[0].rateCycle) {
              case 1:
                this.ruleForm.rateValue2 += '/天'
                break
              case 2:
                this.ruleForm.rateValue2 += '/周'
                break
              case 3:
                this.ruleForm.rateValue2 += '/月'
                break
              case 4:
                this.ruleForm.rateValue2 += '/年'
                break
              default:
                break
            }
          }
          this.ruleForm.isShow = true
          // 代采机构id
          this.ruleForm.employerCmpCode = res.data.fundsCmpCode
          // 交货方式写死
          this.ruleForm.deliveryType = 2
          this.ruleForm.maxPurchaseDays2 = this.ruleForm.maxPurchaseDays + '天'
          this.ruleForm.overdueInterestRate =
            this.ruleForm.overdueInterestRate + '%/天'
          // 货物明细
          this.allGoodsList = (res.data.supplierGoodsList || []).map((item) => {
            item.purchaseUnit = '5'
            item.purchasePrice = ''
            item.purchaseWeight = ''
            item.purchaseQuantity = ''
            item.totalPrice = ''
            return { ...item }
          })
          // 授信id
          this.ruleForm.creditId = this.creditInfoId
          this.ruleFormGoodsList = []
        },
        () => {
          this.ruleForm.isShow = false
        }
      )
    },
    // 获取合作企业列表
    getAgentSupplierList(val) {
      agentSupplierListSupplierCmpByFundsCmpCode(
        { fundsCmpCode: this.ruleForm.employerCmpCode, supplierCmpName: val },
        (res) => {
          this.agentSupplierList = [...res.data]
        }
      )
    },
    getSupplierCmpId() {
      if (this.ruleForm.supplierCmpId && this.ruleForm.employerCmpCode) {
        this.getPlanInfoForAdd()
      }
    },
    // 获取授信详情
    getAgentCreditInfoDetails(value) {
      const data = this.agentCreditInfoList.find((item) => item.id === value)
      this.creditInfoId = data.id
      this.ruleForm.employerCmpCode = data.fundsCmpCode
      this.ruleForm.supplierCmpId = ''
      // 判断是锁价还是缩量   如果只有锁价为1的话  就是缩量   如果锁量是1的话就是缩量  如果两者都是1的话  就可以选择了
      switch (true) {
        case data.lockPrice === 1 && data.lockQuantity === 1:
          this.ruleForm.transType1 = 3
          this.ruleForm.transType = 1
          break
        case data.lockQuantity === 1:
          this.ruleForm.transType = 1
          break
        case data.lockPrice === 1:
          this.ruleForm.transType = 2
          break
      }
      this.getAgentSupplierList('') // 重新请求接口
      this.ruleForm.isShow = false // 把下面的置空清除
      if (this.ruleForm.supplierCmpId && this.ruleForm.employerCmpCode) {
        this.getPlanInfoForAdd()
      }
    },
    // 添加
    addPurchaseGoods() {
      if (!this.ruleForm.employerId) {
        this.$message.error('请先填写代采机构')
        return
      }
      if (!this.ruleForm.supplierCmpId) {
        this.$message.error('请先填写合作企业')
        return
      }
      if (!this.ruleForm.agentRateConfigId) {
        this.$message.error('请先填写费率类型')
        return
      }
      if (this.allGoodsList.length !== 0) {
        this.goodsVisible = true
        return
      }
      // 把不是商品库添加的而且自主添加没改变的剔除出来
      this.autonomousAdditionArr = this.ruleFormGoodsList.filter(
        (item) => !item.id
      )
      // 把商品库的选上
      this.selectedArr = this.ruleFormGoodsList.filter((item) => item.id)
      this.$refs.chooseMatterWeight.dialogTableVisible = true
      // this.$set(this.ruleFormGoodsList, this.ruleFormGoodsList.length, {
      //   purchaseUnit: '5',
      //   purchasePrice: '',
      //   purchaseWeight: ''
      // })
      this.$forceUpdate()
    },
    // 删除
    deleteRow(row, parameter, index) {
      this.$set(row, 'purchasePrice', '')
      this.$set(row, 'purchaseWeight', '')
      this.$set(this.ruleFormGoodsList, index, {})
      this.ruleFormGoodsList.splice(index, 1)
      let total = 0
      this.ruleFormGoodsList.forEach((item) => {
        total += Number(item.totalPrice)
      })
      this.$set(this.ruleForm, 'totalPayment', roundUpToInteger(total))
      if (this.allGoodsList.length > 0) {
        this.$refs.goodsListTable.accessSelected(this.ruleFormGoodsList)
      }
      this.computationalCosts()
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const flag =
            this.ruleFormGoodsList.length === 0
              ? false
              : this.ruleFormGoodsList.every((item) => {
                if (item.id) {
                  return (
                    item.goodsMaterials &&
                      item.goodsName &&
                      item.purchaseUnit &&
                      item.purchasePrice &&
                      item.purchaseWeight
                  )
                }
                return (
                  item.goodsWeights &&
                    item.goodsProduct &&
                    item.goodsSpecs &&
                    item.goodsMaterials &&
                    item.goodsName &&
                    item.purchaseUnit &&
                    item.purchasePrice &&
                    item.purchaseWeight
                )
              })
          if (!flag) {
            this.$message.error('请完善商品信息')
            return
          }
          this.ruleForm.goodsList = this.ruleFormGoodsList.filter(
            (item) =>
              item.goodsMaterials &&
              item.goodsName &&
              item.purchaseUnit &&
              item.purchasePrice &&
              item.purchaseWeight
          )
          this.ruleForm.goodsList.forEach((item) => {
            item.goodsWeights =
              item.goodsWeights === '-' ? '' : item.goodsWeights
          })
          agentPurchasePlanAdd({ ...this.ruleForm }, (res) => {
            this.$message.success('成功！')
            clearTimeout(this.times)
            this.times = setTimeout(() => {
              this.ruleFormGoodsList = []
              this.ruleForm = {
                creditId: '',
                transType: '',
                supplierCmpId: '',
                employerId: '',
                deliveryType: 2,
                invoicingType: '', // 开票方式
                agentRateConfigList: [{ rateValue: '' }],
                goodsList: [], // 货物明细
                paymentConfigList: [], // 付款比例配置
                custCmpCode: this.$getUserInfoParameter('cmpId'),
                totalPayment: 0 // 货款总额
              }
              this.$router.push({
                path: '/purchasePlanManage/index'
              })
            }, 1000)
          })
        }
      })
    },
    // 采购单价
    getUnitWeight(row) {
      if (row.purchaseWeight && row.purchasePrice) {
        this.$set(
          row,
          'totalPrice',
          roundUpToInteger(
            Number(row.purchaseWeight) * Number(row.purchasePrice)
          )
        )
        let total = 0
        let weight = 0
        const arr = []
        this.ruleFormGoodsList.forEach((item) => {
          total += Number(item.totalPrice) || 0
          weight += Number(item.purchaseWeight) || 0
          // 当为阶梯计费的时候  进行算代采费用
          if (
            this.ruleForm.rateType === 2 &&
            this.ruleForm.rateLadderConfigList &&
            this.ruleForm.rateLadderConfigList.length > 0
          ) {
            this.ruleForm.rateLadderConfigList.forEach((val, index) => {
              // 挡在区间之中时  就去区间中的base  当不是时就取相应的
              // 当值超过最后一个的时候
              if (
                Number(item.purchasePrice) >= Number(val.lowerLimit) &&
                index === this.ruleForm.rateLadderConfigList.length - 1
              ) {
                arr.push({ base: val.base, weight: item.purchaseWeight })
                return
              }
              // 当值小于最后一个的时候  而且是第一个的时候
              if (
                Number(item.purchasePrice) <= Number(val.upperLimit) &&
                index === 0
              ) {
                arr.push({ base: val.base, weight: item.purchaseWeight })
                return
              }
              if (
                Number(item.purchasePrice) >= Number(val.lowerLimit) &&
                Number(item.purchasePrice) <= Number(val.upperLimit)
              ) {
                arr.push({ base: val.base, weight: item.purchaseWeight })
              }
            })
            this.$forceUpdate()
          }
        })
        if (arr.length > 0) {
          this.ruleForm.estimateCost = 0
          arr.forEach((item) => {
            this.ruleForm.estimateCost +=
              Number(item.base) * Number(item.weight)
          })
        }
        this.$set(this.ruleForm, 'totalPayment', roundUpToInteger(total))
        this.$set(this.ruleForm, 'totalWeight', weight)
        return
      }
      row.totalPrice = 0
    },
    // 选择货物名 获取当前货物明细
    getgoodsRow(row, index) {
      const filterObj = this.ruleFormGoodsList.find((item, num) => {
        return item.id === row.id && num !== index
      })
      if (
        this.ruleFormGoodsList.length > 0 &&
        filterObj &&
        filterObj.id === row.id
      ) {
        this.$message.error('不能选择同一个商品')
        this.$set(this.ruleFormGoodsList, index, {})
        return
      }
      const obj = this.allGoodsList.find((item) => item.id === row.id)
      this.$set(this.ruleFormGoodsList, index, {
        ...obj,
        purchaseUnit: row.purchaseUnit,
        purchasePrice: row.purchasePrice,
        purchaseWeight: row.purchaseWeight
      })
    },
    cancel() {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-form {
  .detailsMargin {
    width: 100%;
    /deep/.el-form-item__content {
      width: calc(100% - 140px);
    }
  }
  .flexBox {
    display: flex;
    span {
      width: 38px;
    }
  }
  .button-combination {
    padding-top: 20px;
    height: 50px;
  }
  .purchase-goods {
    width: 100%;
    margin-bottom: 20px;
    .tip {
      flex: 1;
      font-size: 12px;
      color: $pointClearColor;
      margin-right: 14px;
      float: left;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
      .el-input__prefix{
        right: 5px;
      }
      .el-radio {
        margin-right: 20px;
        .el-radio__label {
          padding-left: 0;
        }
      }
    }

    /deep/ .el-input-number__decrease,
    /deep/ .el-input-number__increase {
      width: 20px;
    }
  }
}
</style>
